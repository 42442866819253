export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94')
];

export const server_loads = [];

export const dictionary = {
		"/": [13],
		"/-debug": [48],
		"/(other)/bulk-labels": [32],
		"/(other)/bulk-labels/[hashedId]": [33],
		"/change-password": [49],
		"/clients": [50],
		"/forgot-password": [51],
		"/help": [52],
		"/(customs)/hs-codes": [14],
		"/(customs)/i-oss": [15],
		"/(customs)/i-oss/[id]": [16,[2]],
		"/(customs)/i-oss/[id]/attachments": [17,[2]],
		"/(customs)/i-oss/[id]/notes": [18,[2]],
		"/(customs)/i-oss/[id]/overview": [19,[2]],
		"/(customs)/i-oss/[id]/state-changes": [20,[2]],
		"/(outbound)/inventory-summary": [47],
		"/(other)/invoices": [34],
		"/(other)/invoices/[id]": [35,[5]],
		"/(other)/invoices/[id]/notes": [36,[5]],
		"/(other)/invoices/[id]/overview": [37,[5]],
		"/login": [53],
		"/notifications": [54],
		"/parcels": [55,[8]],
		"/parcels/[id]": [56,[8,9]],
		"/parcels/[id]/accounting": [57,[8,9]],
		"/parcels/[id]/attachments": [58,[8,9]],
		"/parcels/[id]/declarations": [59,[8,9]],
		"/parcels/[id]/delivery": [60,[8,9]],
		"/parcels/[id]/ehub": [61,[8,9]],
		"/parcels/[id]/gps": [62,[8,9]],
		"/parcels/[id]/notes": [63,[8,9]],
		"/parcels/[id]/overview": [64,[8,9]],
		"/parcels/[id]/rfid": [65,[8,9]],
		"/parcels/[id]/special-instructions": [66,[8,9]],
		"/parcels/[id]/trucking-jobs": [67,[8,9]],
		"/settings": [68],
		"/(other)/shortcodes": [38],
		"/(other)/shortcodes/[id]": [39,[6]],
		"/(other)/shortcodes/[id]/consignee-address": [40,[6]],
		"/(other)/surcharge-rates": [41],
		"/(customs)/taric-codes": [21],
		"/trucking-jobs": [69,[10]],
		"/trucking-jobs/[id]": [70,[10,11]],
		"/trucking-jobs/[id]/attachments": [71,[10,11]],
		"/trucking-jobs/[id]/gps": [72,[10,11]],
		"/trucking-jobs/[id]/notes": [73,[10,11]],
		"/trucking-jobs/[id]/overview": [74,[10,11]],
		"/trucking-jobs/[id]/parcels": [75,[10,11]],
		"/trucking-jobs/[id]/unit-load-devices": [76,[10,11]],
		"/(other)/ulds": [42],
		"/(other)/ulds/[id]": [43,[7]],
		"/(other)/ulds/[id]/attachments": [44,[7]],
		"/(other)/ulds/[id]/notes": [45,[7]],
		"/(other)/ulds/[id]/overview": [46,[7]],
		"/(customs)/vat/numbers": [22],
		"/(customs)/vat/numbers/[id]": [23,[3]],
		"/(customs)/vat/numbers/[id]/attachments": [24,[3]],
		"/(customs)/vat/numbers/[id]/overview": [25,[3]],
		"/(customs)/vat/requests": [26],
		"/(customs)/vat/requests/[id]": [27,[4]],
		"/(customs)/vat/requests/[id]/attachments": [28,[4]],
		"/(customs)/vat/requests/[id]/notes": [29,[4]],
		"/(customs)/vat/requests/[id]/registration": [30,[4]],
		"/(customs)/vat/requests/[id]/state-changes": [31,[4]],
		"/waybills": [77],
		"/waybills/[id]": [78,[12]],
		"/waybills/[id]/accounting": [79,[12]],
		"/waybills/[id]/attachments": [80,[12]],
		"/waybills/[id]/declaration": [81,[12]],
		"/waybills/[id]/ehub-transfer": [82,[12]],
		"/waybills/[id]/flights": [83,[12]],
		"/waybills/[id]/inbound-transport": [84,[12]],
		"/waybills/[id]/noas": [85,[12]],
		"/waybills/[id]/notes": [86,[12]],
		"/waybills/[id]/outbound": [87,[12]],
		"/waybills/[id]/overview": [88,[12]],
		"/waybills/[id]/parcels": [89,[12]],
		"/waybills/[id]/prealerts": [90,[12]],
		"/waybills/[id]/prealerts/[prealertId]": [91,[12]],
		"/waybills/[id]/transport": [92,[12]],
		"/waybills/[id]/unit-load-devices": [93,[12]],
		"/waybills/[id]/waybill": [94,[12]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';